const CACHE_NAME = 'my-pwa-cache-v1'
const urlsToCache = ['/', '/index.html', '/manifest.json', '/profile.png', '/profile.png']
self.__WB_MANIFEST = []
self.addEventListener('install', (event) => {
  event.waitUntil(
    caches.open(CACHE_NAME).then((cache) => {
      return cache.addAll(urlsToCache)
    })
  )
})

self.addEventListener('fetch', (event) => {
  event.respondWith(
    caches.match(event.request).then((response) => {
      return response || fetch(event.request)
    })
  )
})
